(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name components.filter:htmlToPlainText
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('components')
    .filter('htmlToPlainText', htmlToPlainText);

  function htmlToPlainText() {
    return function (html) {
      return html ? String(html).replace(/<[^>]+>/gm, ' ') : ' ';
    };
  }
}());
